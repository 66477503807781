// import React, { useState } from 'react';
// import './QuickDeposit.css';

// const QuickDeposit = () => {
//   const [amount, setAmount] = useState('');
//   const [isBankDetailsVisible, setBankDetailsVisible] = useState(false);
//   const [referenceNumber, setReferenceNumber] = useState('');

//   const bankDetails = {
//     bankName: 'Indian Overseas Bank',
//     accountNumber: '234402000000397',
//     ifscCode: 'IOBA0002344',
//     accountName: 'SS CONTRACTORS',
//   };

//   // Function to copy text to clipboard
//   const copyToClipboard = (text) => {
//     navigator.clipboard.writeText(text);
//     alert('Copied to clipboard!');
//   };

//   const handlePayClick = () => {
//     if (amount) {
//       setBankDetailsVisible(true);
//     } else {
//       alert('Please enter an amount.');
//     }
//   };

//   const handleQuickDepositClick = () => {
//     if (!referenceNumber) {
//       alert('Please enter a reference number.');
//     } else {
//       console.log('Quick Deposit initiated with reference:', referenceNumber);
//     }
//   };

//   return (
//     <div className="quick-deposit-container">
//       <div className="quick-deposit-box">
//         {/* PayinFy Logo */}
//         <div className="logo-section">
//           <img src="/your-payinfy-logo.png" alt="PayinFy Logo" className="payinfy-logo" />
//         </div>
//         <h2>Quick Deposit</h2>
//         {!isBankDetailsVisible ? (
//           <>
//             <div className="amount-section">
//               <label htmlFor="amount">Enter Amount</label>
//               <input
//                 type="number"
//                 id="amount"
//                 placeholder="Enter amount"
//                 value={amount}
//                 onChange={(e) => setAmount(e.target.value)}
//               />
//               <button className="pay-button" onClick={handlePayClick}>
//                 Pay
//               </button>
//             </div>
//           </>
//         ) : (
//           <>
//             <div className="bank-details">
//               <h3>Bank Details</h3>
//               <div className="bank-info">
//                 <div>
//                   <label>Bank Name:</label>
//                   <span>{bankDetails.bankName}</span>
//                   <span className="copy-icon" onClick={() => copyToClipboard(bankDetails.bankName)}>
//                     &#x2398;
//                   </span>
//                 </div>
//                 <div>
//                   <label>Account Number:</label>
//                   <span>{bankDetails.accountNumber}</span>
//                   <span className="copy-icon" onClick={() => copyToClipboard(bankDetails.accountNumber)}>
//                     &#x2398;
//                   </span>
//                 </div>
//                 <div>
//                   <label>IFSC Code:</label>
//                   <span>{bankDetails.ifscCode}</span>
//                   <span className="copy-icon" onClick={() => copyToClipboard(bankDetails.ifscCode)}>
//                     &#x2398;
//                   </span>
//                 </div>
//                 <div>
//                   <label>Account Name:</label>
//                   <span>{bankDetails.accountName}</span>
//                   <span className="copy-icon" onClick={() => copyToClipboard(bankDetails.accountName)}>
//                     &#x2398;
//                   </span>
//                 </div>
//               </div>
//             </div>

//             <div className="reference-section">
//               <label htmlFor="reference-number">Enter Reference Number</label>
//               <input
//                 type="text"
//                 id="reference-number"
//                 placeholder="Enter reference number"
//                 value={referenceNumber}
//                 onChange={(e) => setReferenceNumber(e.target.value)}
//               />
//               <button className="quick-deposit-button" onClick={handleQuickDepositClick}>
//                 Quick Deposit
//               </button>
//             </div>
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// export default QuickDeposit;




// import React, { useState } from 'react';
// import './QuickDeposit.css';

// const QuickDeposit = () => {
//   const [amount, setAmount] = useState('');
//   const [isBankDetailsVisible, setBankDetailsVisible] = useState(false);
//   const [referenceNumber, setReferenceNumber] = useState('');

//   const bankDetails = {
//     bankName: 'Indian Overseas Bank',
//     accountNumber: '234402000000397',
//     ifscCode: 'IOBA0002344',
//     accountName: 'SS CONTRACTORS',
//   };

//   const handlePayClick = () => {
//     if (amount) {
//       setBankDetailsVisible(true);
//     } else {
//       alert('Please enter an amount.');
//     }
//   };

//   const handleQuickDepositClick = async () => {
//     if (!referenceNumber) {
//       alert('Please enter a reference number.');
//     } else {
//       try {
//         const response = await fetch('/quick-deposit/', {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//             'Authorization': `Bearer ${token}`, // Assuming the token is stored or passed correctly
//             'Secret-Key': secretKey,  // Add secret key from backend if needed
//           },
//           body: JSON.stringify({ reference_number: referenceNumber, amount: amount }),
//         });

//         const data = await response.json();

//         if (data.status === 'SUCCESS') {
//           alert('Quick Deposit successful!');
//         } else {
//           alert(`Quick Deposit failed: ${data.message}`);
//         }
//       } catch (error) {
//         console.error('Error during Quick Deposit:', error);
//         alert('Quick Deposit failed. Please try again.');
//       }
//     }
//   };

//   return (
//     <div className="quick-deposit-container">
//       <div className="quick-deposit-box">
//         <div className="logo-section">
//           <img src="/your-payinfy-logo.png" alt="PayinFy Logo" className="payinfy-logo" />
//         </div>
//         <h2>Quick Deposit</h2>
//         {!isBankDetailsVisible ? (
//           <>
//             <div className="amount-section">
//               <label htmlFor="amount">Enter Amount</label>
//               <input
//                 type="number"
//                 id="amount"
//                 placeholder="Enter amount"
//                 value={amount}
//                 onChange={(e) => setAmount(e.target.value)}
//               />
//               <button className="pay-button" onClick={handlePayClick}>
//                 Pay
//               </button>
//             </div>
//           </>
//         ) : (
//           <>
//             <div className="bank-details">
//               <h3>Bank Details</h3>
//               <div className="bank-info">
//                 <div>
//                   <label>Bank Name:</label>
//                   <span>{bankDetails.bankName}</span>
//                 </div>
//                 <div>
//                   <label>Account Number:</label>
//                   <span>{bankDetails.accountNumber}</span>
//                 </div>
//                 <div>
//                   <label>IFSC Code:</label>
//                   <span>{bankDetails.ifscCode}</span>
//                 </div>
//                 <div>
//                   <label>Account Name:</label>
//                   <span>{bankDetails.accountName}</span>
//                 </div>
//               </div>
//             </div>

//             <div className="reference-section">
//               <label htmlFor="reference-number">Enter Reference Number</label>
//               <input
//                 type="text"
//                 id="reference-number"
//                 placeholder="Enter reference number"
//                 value={referenceNumber}
//                 onChange={(e) => setReferenceNumber(e.target.value)}
//               />
//               <button className="quick-deposit-button" onClick={handleQuickDepositClick}>
//                 Quick Deposit
//               </button>
//             </div>
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// export default QuickDeposit;



// import React, { useState } from 'react';
// import './QuickDeposit.css';

// const QuickDeposit = () => {
//   const [amount, setAmount] = useState('');
//   const [referenceNumber, setReferenceNumber] = useState('');
//   const [widgetLink, setWidgetLink] = useState('');

//   const bankDetails = {
//     bankName: 'Indian Overseas Bank',
//     accountNumber: '234402000000397',
//     ifscCode: 'IOBA0002344',
//     accountName: 'SS CONTRACTORS',
//   };

//   // Function to copy text to clipboard
//   const copyToClipboard = (text) => {
//     navigator.clipboard.writeText(text);
//     alert('Copied to clipboard!');
//   };

//   const handleQuickDepositClick = async () => {
//     try {
//       const response = await fetch('/quick-deposit/', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer YOUR_TOKEN`, // Replace with actual token
//           'Secret-Key': `YOUR_SECRET_KEY`,       // Replace with actual secret key
//         },
//         body: JSON.stringify({ amount: amount }),
//       });

//       const data = await response.json();

//       if (data.status === 'SUCCESS') {
//         setWidgetLink(data.payment_widget_url);
//       } else {
//         alert(`Error: ${data.message}`);
//       }
//     } catch (error) {
//       console.error('Error during Quick Deposit:', error);
//       alert('Error processing the payment. Please try again.');
//     }
//   };

//   const handleReferenceSubmit = async () => {
//     try {
//       const response = await fetch(`/reference-scraping/${widgetLink.split('/').pop()}/`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ reference_number: referenceNumber }),
//       });

//       const data = await response.json();

//       alert(data.message);
//     } catch (error) {
//       console.error('Error:', error);
//     }
//   };

//   return (
//     <div className="quick-deposit-container">
//       <div className="quick-deposit-box">
//         <div className="logo-section">
//           <img src="/your-payinfy-logo.png" alt="PayinFy Logo" className="payinfy-logo" />
//         </div>
//         <h2>Quick Deposit</h2>

//         {!widgetLink ? (
//           <>
//             <div className="amount-section">
//               <label htmlFor="amount">Enter Amount</label>
//               <input
//                 type="number"
//                 id="amount"
//                 placeholder="Enter amount"
//                 value={amount}
//                 onChange={(e) => setAmount(e.target.value)}
//               />
//               <button className="pay-button" onClick={handleQuickDepositClick}>
//                 Pay
//               </button>
//             </div>
//           </>
//         ) : (
//           <>
//             <div className="bank-details">
//               <h3>Bank Details</h3>
//               <div className="bank-info">
//                 <div>
//                   <label>Bank Name:</label>
//                   <span>{bankDetails.bankName}</span>
//                   <span className="copy-icon" onClick={() => copyToClipboard(bankDetails.bankName)}>
//                     &#x2398;
//                   </span>
//                 </div>
//                 <div>
//                   <label>Account Number:</label>
//                   <span>{bankDetails.accountNumber}</span>
//                   <span className="copy-icon" onClick={() => copyToClipboard(bankDetails.accountNumber)}>
//                     &#x2398;
//                   </span>
//                 </div>
//                 <div>
//                   <label>IFSC Code:</label>
//                   <span>{bankDetails.ifscCode}</span>
//                   <span className="copy-icon" onClick={() => copyToClipboard(bankDetails.ifscCode)}>
//                     &#x2398;
//                   </span>
//                 </div>
//                 <div>
//                   <label>Account Name:</label>
//                   <span>{bankDetails.accountName}</span>
//                   <span className="copy-icon" onClick={() => copyToClipboard(bankDetails.accountName)}>
//                     &#x2398;
//                   </span>
//                 </div>
//               </div>
//             </div>

//             <div className="reference-section">
//               <label htmlFor="reference-number">Enter Reference Number</label>
//               <input
//                 type="text"
//                 id="reference-number"
//                 placeholder="Enter reference number"
//                 value={referenceNumber}
//                 onChange={(e) => setReferenceNumber(e.target.value)}
//               />
//               <button className="quick-deposit-button" onClick={handleReferenceSubmit}>
//                 Quick Deposit
//               </button>
//             </div>

//             <div className="widget-link-section">
//               <h3>Click the link below to complete the payment:</h3>
//               <a href={widgetLink} target="_blank" rel="noopener noreferrer">
//                 Open Payment Widget
//               </a>
//             </div>
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// export default QuickDeposit;


// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom'; // To get the transaction_id from the URL
// import './QuickDeposit.css';

// const PaymentWidget = () => {
//   const { transaction_id } = useParams();  // Get the transaction_id from the URL
//   const [transactionDetails, setTransactionDetails] = useState(null);
//   const [referenceNumber, setReferenceNumber] = useState('');

//   // Fetch transaction details from the backend when the component loads
//   useEffect(() => {
//     fetch(`https://payin.payinfy.com/api/transaction-details/${transaction_id}/`)
//       .then(response => response.json())
//       .then(data => {
//         if (data.status === 'SUCCESS') {
//           setTransactionDetails(data.transaction);
//         } else {
//           alert(data.message);
//         }
//       })
//       .catch(error => {
//         console.error('Error fetching transaction details:', error);
//         alert('Error fetching transaction details');
//       });
//   }, [transaction_id]);

//   const handleReferenceSubmit = () => {
//     fetch(`https://payin.payinfy.com/api/reference-scraping/${transaction_id}/`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ reference_number: referenceNumber }),
//     })
//       .then(response => response.json())
//       .then(data => {
//         alert(data.message);
//       })
//       .catch(error => {
//         console.error('Error:', error);
//       });
//   };

//   if (!transactionDetails) {
//     return <div>Loading transaction details...</div>;
//   }

//   return (
//     <div className="quick-deposit-container">
//       <div className="quick-deposit-box">
//         <h2>QUICK DEPOSIT</h2>
//         <p>Amount to Pay: <strong>{transactionDetails.amount} {transactionDetails.currency}</strong></p>
        
//         <div className="bank-details">
//           <h3>Bank Details</h3>
//           <div className="bank-info">
//             <div>
//               <label>Bank Name:</label>
//               <span>Indian Overseas Bank</span>
//             </div>
//             <div>
//               <label>Account Number:</label>
//               <span>234402000000397</span>
//             </div>
//             <div>
//               <label>IFSC Code:</label>
//               <span>IOBA0002344</span>
//             </div>
//             <div>
//               <label>Account Name:</label>
//               <span>SS CONTRACTORS</span>
//             </div>
//           </div>
//         </div>

//         <div className="reference-section">
//           <label htmlFor="reference-number">Enter Reference Number</label>
//           <input
//             type="text"
//             id="reference-number"
//             placeholder="Enter reference number"
//             value={referenceNumber}
//             onChange={(e) => setReferenceNumber(e.target.value)}
//           />
//           <button className="quick-deposit-button" onClick={handleReferenceSubmit}>
//             Submit Reference Number
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PaymentWidget;


// QuickDeposit.js

// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom'; // To get the transaction_id from the URL
// import './QuickDeposit.css'; // Import your CSS styling

// const QuickDeposit = () => {
//   const { transaction_id } = useParams();  // Get the transaction_id from the URL
//   const [transactionDetails, setTransactionDetails] = useState(null);
//   const [referenceNumber, setReferenceNumber] = useState('');

//   // Fetch transaction details from the backend when the component loads
//   useEffect(() => {
//     fetch(`https://payin.payinfy.com/transaction-details/${transaction_id}/`)
//       .then(response => response.json())
//       .then(data => {
//         if (data.status === 'SUCCESS') {
//           setTransactionDetails(data.transaction);
//         } else {
//           alert(data.message);
//         }
//       })
//       .catch(error => {
//         console.error('Error fetching transaction details:', error);
//         alert('Error fetching transaction details');
//       });
//   }, [transaction_id]);

//   const handleReferenceSubmit = () => {
//     if (!referenceNumber) {
//       alert('Please enter a reference number.');
//       return;
//     }

//     fetch(`https://payin.payinfy.com/reference-scraping/${transaction_id}/`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ reference_number: referenceNumber }),
//     })
//       .then(response => response.json())
//       .then(data => {
//         if (data.status === 'SUCCESS' || data.status === 'Success') {
//           alert(data.message);
//         } else {
//           alert(`Error: ${data.message}`);
//         }
//       })
//       .catch(error => {
//         console.error('Error:', error);
//         alert('An error occurred while submitting the reference number.');
//       });
//   };

//   if (!transactionDetails) {
//     return <div>Loading transaction details...</div>;
//   }

//   return (
//     <div className="quick-deposit-container">
//       <div className="quick-deposit-box">
//         <h2>QUICK DEPOSIT</h2>
//         <p>
//           Amount to Pay:{' '}
//           <strong>
//             {transactionDetails.amount} {transactionDetails.currency}
//           </strong>
//         </p>

//         <div className="bank-details">
//           <h3>Bank Details</h3>
//           <div className="bank-info">
//             <div>
//               <label>Bank Name:</label>
//               <span>Indian Overseas Bank</span>
//             </div>
//             <div>
//               <label>Account Number:</label>
//               <span>234402000000397</span>
//             </div>
//             <div>
//               <label>IFSC Code:</label>
//               <span>IOBA0002344</span>
//             </div>
//             <div>
//               <label>Account Name:</label>
//               <span>SS CONTRACTORS</span>
//             </div>
//           </div>
//         </div>

//         <div className="reference-section">
//           <label htmlFor="reference-number">Enter Reference Number</label>
//           <input
//             type="text"
//             id="reference-number"
//             placeholder="Enter reference number"
//             value={referenceNumber}
//             onChange={(e) => setReferenceNumber(e.target.value)}
//           />
//           <button className="quick-deposit-button" onClick={handleReferenceSubmit}>
//             Submit Reference Number
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default QuickDeposit;


// QuickDeposit.js

// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import './QuickDeposit.css'; // Ensure this file includes the required CSS for animations

// const QuickDeposit = () => {
//   const { transaction_id } = useParams();
//   const [transactionDetails, setTransactionDetails] = useState(null);
//   const [referenceNumber, setReferenceNumber] = useState('');
//   const [transactionStatus, setTransactionStatus] = useState(null); // New state variable
//   const [isSubmitting, setIsSubmitting] = useState(false); // To handle loading state during submission

//   // Fetch transaction details from the backend when the component loads
//   useEffect(() => {
//     fetch(`https://payin.payinfy.com/api/transaction-details/${transaction_id}/`)
//       .then(response => response.json())
//       .then(data => {
//         if (data.status === 'SUCCESS') {
//           setTransactionDetails(data.transaction);
//         } else {
//           alert(data.message);
//         }
//       })
//       .catch(error => {
//         console.error('Error fetching transaction details:', error);
//         alert('Error fetching transaction details');
//       });
//   }, [transaction_id]);

//   const handleReferenceSubmit = () => {
//     if (!referenceNumber) {
//       alert('Please enter a reference number.');
//       return;
//     }

//     setIsSubmitting(true); // Start loading
//     fetch(`https://payin.payinfy.com/api/reference-scraping/${transaction_id}/`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ reference_number: referenceNumber }),
//     })
//       .then(response => response.json())
//       .then(data => {
//         setIsSubmitting(false); // End loading
//         if (data.status === 'Success') {
//           // Update the transaction amount with the deposited amount
//           setTransactionDetails(prevDetails => ({
//             ...prevDetails,
//             amount: data.amount,
//           }));
//           setTransactionStatus('SUCCESS');
//         } else {
//           setTransactionStatus('FAILED');
//         }
//       })
//       .catch(error => {
//         setIsSubmitting(false); // End loading
//         console.error('Error:', error);
//         alert('An error occurred while submitting the reference number.');
//       });
//   };

//   if (!transactionDetails) {
//     return <div>Loading transaction details...</div>;
//   }

//   return (
//     <div className="quick-deposit-container">
//       <div className="quick-deposit-box">
//         {/* Display the animated success or failure message */}
//         {transactionStatus === 'SUCCESS' && (
//           <div className="success-message animate">
//             Quick deposit amount of ₹{transactionDetails.amount} Successful
//           </div>
//         )}
//         {transactionStatus === 'FAILED' && (
//           <div className="failed-message animate">
//             Quick deposit failed. Please try again.
//           </div>
//         )}

//         {/* Show the form only if the transaction is not completed */}
//         {!transactionStatus && (
//           <>
//             <h2>QUICK DEPOSIT</h2>
//             <p>
//               Amount to Pay:{' '}
//               <strong>
//                 ₹{transactionDetails.amount} {transactionDetails.currency}
//               </strong>
//             </p>

//             <div className="bank-details">
//               <h3>Bank Details</h3>
//               <div className="bank-info">
//                 <div>
//                   <label>Bank Name:</label>
//                   <span>Indian Overseas Bank</span>
//                 </div>
//                 <div>
//                   <label>Account Number:</label>
//                   <span>234402000000397</span>
//                 </div>
//                 <div>
//                   <label>IFSC Code:</label>
//                   <span>IOBA0002344</span>
//                 </div>
//                 <div>
//                   <label>Account Name:</label>
//                   <span>SS CONTRACTORS</span>
//                 </div>
//               </div>
//             </div>

//             <div className="reference-section">
//               <label htmlFor="reference-number">Enter Reference Number</label>
//               <input
//                 type="text"
//                 id="reference-number"
//                 placeholder="Enter reference number"
//                 value={referenceNumber}
//                 onChange={(e) => setReferenceNumber(e.target.value)}
//               />
//               <button
//                 className="quick-deposit-button"
//                 onClick={handleReferenceSubmit}
//                 disabled={isSubmitting} // Disable the button while submitting
//               >
//                 {isSubmitting ? 'Submitting...' : 'Submit Reference Number'}
//               </button>
//             </div>
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// export default QuickDeposit;




// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import './QuickDeposit.css'; // Ensure this file includes the required CSS for animations

// const QuickDeposit = () => {
//   const { transaction_id } = useParams();
//   const [transactionDetails, setTransactionDetails] = useState(null);
//   const [referenceNumber, setReferenceNumber] = useState('');
//   const [transactionStatus, setTransactionStatus] = useState(null);
//   const [isSubmitting, setIsSubmitting] = useState(false);

//   // Fetch transaction details from the backend when the component loads
//   useEffect(() => {
//     fetch(`https://payin.payinfy.com/api/transaction-details/${transaction_id}/`)
//       .then(response => response.json())
//       .then(data => {
//         if (data.status === 'SUCCESS') {
//           setTransactionDetails(data.transaction);
//         } else {
//           alert(data.message);
//         }
//       })
//       .catch(error => {
//         console.error('Error fetching transaction details:', error);
//         alert('Error fetching transaction details');
//       });
//   }, [transaction_id]);

//   const handleReferenceSubmit = () => {
//     if (!referenceNumber) {
//       alert('Please enter a reference number.');
//       return;
//     }

//     setIsSubmitting(true); // Start loading
//     fetch(`https://payin.payinfy.com/api/reference-scraping/${transaction_id}/`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ reference_number: referenceNumber }),
//     })
//       .then(response => response.json())
//       .then(data => {
//         setIsSubmitting(false); // End loading
//         if (data.status === 'Success') {
//           // Update the transaction amount with the deposited amount
//           setTransactionDetails(prevDetails => ({
//             ...prevDetails,
//             amount: data.amount,
//           }));
//           setTransactionStatus('SUCCESS');

//           // Redirect to the callback URL after success
//           if (data.payin_callback_url) {
//             window.location.href = data.payin_callback_url;
//           }
//         } else {
//           setTransactionStatus('FAILED');

//           // Redirect to the callback URL after failure
//           if (data.payin_callback_url) {
//             window.location.href = data.payin_callback_url;
//           }
//         }
//       })
//       .catch(error => {
//         setIsSubmitting(false); // End loading
//         console.error('Error:', error);
//         alert('An error occurred while submitting the reference number.');
//       });
//   };

//   if (!transactionDetails) {
//     return <div>Loading transaction details...</div>;
//   }

//   return (
//     <div className="quick-deposit-container">
//       <div className="quick-deposit-box">
//         {/* Display the animated success or failure message */}
//         {transactionStatus === 'SUCCESS' && (
//           <div className="success-message animate">
//             Quick deposit amount of ₹{transactionDetails.amount} Successful
//           </div>
//         )}
//         {transactionStatus === 'FAILED' && (
//           <div className="failed-message animate">
//             Quick deposit failed. Please try again.
//           </div>
//         )}

//         {/* Show the form only if the transaction is not completed */}
//         {!transactionStatus && (
//           <>
//             <h2>QUICK DEPOSIT</h2>
//             <p>
//               Amount to Pay:{' '}
//               <strong>
//                 ₹{transactionDetails.amount} {transactionDetails.currency}
//               </strong>
//             </p>

//             <div className="bank-details">
//               <h3>Bank Details</h3>
//               <div className="bank-info">
//                 <div>
//                   <label>Bank Name:</label>
//                   <span>Indian Overseas Bank</span>
//                 </div>
//                 <div>
//                   <label>Account Number:</label>
//                   <span>234402000000397</span>
//                 </div>
//                 <div>
//                   <label>IFSC Code:</label>
//                   <span>IOBA0002344</span>
//                 </div>
//                 <div>
//                   <label>Account Name:</label>
//                   <span>SS CONTRACTORS</span>
//                 </div>
//               </div>
//             </div>

//             <div className="reference-section">
//               <label htmlFor="reference-number">Enter Reference Number</label>
//               <input
//                 type="text"
//                 id="reference-number"
//                 placeholder="Enter reference number"
//                 value={referenceNumber}
//                 onChange={(e) => setReferenceNumber(e.target.value)}
//               />
//               <button
//                 className="quick-deposit-button"
//                 onClick={handleReferenceSubmit}
//                 disabled={isSubmitting} // Disable the button while submitting
//               >
//                 {isSubmitting ? 'Submitting...' : 'Submit Reference Number'}
//               </button>
//             </div>
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// export default QuickDeposit;


// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import './QuickDeposit.css'; // Ensure this file includes the required CSS for animations

// const QuickDeposit = () => {
//   const { transaction_id } = useParams();
//   const [transactionDetails, setTransactionDetails] = useState(null);
//   const [referenceNumber, setReferenceNumber] = useState('');
//   const [transactionStatus, setTransactionStatus] = useState(null);
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const [isProcessing, setIsProcessing] = useState(false); // New state variable for processing
//   const [attemptsLeft, setAttemptsLeft] = useState(5); // Total 5 attempts
//   const [payinCallbackUrl, setPayinCallbackUrl] = useState(null); // Store callback URL

//   // Fetch transaction details from the backend when the component loads
//   useEffect(() => {
//     fetch(`https://payin.payinfy.com/api/transaction-details/${transaction_id}/`)
//       .then(response => response.json())
//       .then(data => {
//         if (data.status === 'SUCCESS') {
//           setTransactionDetails(data.transaction);
//           setPayinCallbackUrl(data.transaction.payin_callback_url); // Store callback URL
//         } else {
//           alert(data.message);
//         }
//       })
//       .catch(error => {
//         console.error('Error fetching transaction details:', error);
//         alert('Error fetching transaction details');
//       });
//   }, [transaction_id]);

//   const handleReferenceSubmit = () => {
//     if (!referenceNumber) {
//       alert('Please enter a reference number.');
//       return;
//     }

//     if (attemptsLeft <= 0) {
//       alert('No attempts left.');
//       return;
//     }

//     setIsSubmitting(true); // Start loading
//     setIsProcessing(true); // Show processing loader

//     fetch(`https://payin.payinfy.com/api/reference-scraping/${transaction_id}/`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ reference_number: referenceNumber }),
//     })
//       .then(response => response.json())
//       .then(data => {
//         setIsSubmitting(false); // End loading
//         setIsProcessing(false); // End processing loader

//         if (data.status === 'Success') {
//           // Update the transaction amount with the deposited amount
//           setTransactionDetails(prevDetails => ({
//             ...prevDetails,
//             amount: data.amount,
//           }));
//           setTransactionStatus('SUCCESS');

//           // Redirect to the callback URL after success
//           if (data.payin_callback_url) {
//             window.location.href = data.payin_callback_url;
//           }
//         } else {
//           // Decrement attempts left
//           setAttemptsLeft(prevAttempts => prevAttempts - 1);

//           if (attemptsLeft - 1 > 0) {
//             alert(`Transaction failed. You have ${attemptsLeft - 1} more attempts left.`);
//           } else {
//             setTransactionStatus('FAILED');

//             // Redirect to the callback URL after failure
//             if (data.payin_callback_url || payinCallbackUrl) {
//               window.location.href = data.payin_callback_url || payinCallbackUrl;
//             } else {
//               alert('Transaction failed and no callback URL provided.');
//             }
//           }
//         }
//       })
//       .catch(error => {
//         setIsSubmitting(false); // End loading
//         setIsProcessing(false); // End processing loader
//         console.error('Error:', error);
//         alert('An error occurred while submitting the reference number.');
//       });
//   };

//   // Warn the user before closing or refreshing the window during processing
//   useEffect(() => {
//     const handleBeforeUnload = (e) => {
//       if (isProcessing) {
//         e.preventDefault();
//         e.returnValue = '';
//       }
//     };

//     if (isProcessing) {
//       window.addEventListener('beforeunload', handleBeforeUnload);
//     } else {
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//     }

//     return () => {
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//     };
//   }, [isProcessing]);

//   if (!transactionDetails) {
//     return <div>Loading transaction details...</div>;
//   }

//   return (
//     <div className="quick-deposit-container">
//       <div className="quick-deposit-box">
//         {/* Display the animated success or failure message */}
//         {transactionStatus === 'SUCCESS' && (
//           <div className="success-message animate">
//             Quick deposit amount of ₹{transactionDetails.amount} Successful
//           </div>
//         )}
//         {transactionStatus === 'FAILED' && (
//           <div className="failed-message animate">
//             Quick deposit failed. Please try again.
//           </div>
//         )}

//         {/* Show the processing loader */}
//         {isProcessing && (
//           <div className="processing-overlay">
//             <div className="loader"></div>
//             <p>Your transaction is being processed.</p>
//             <p>Please don't close or refresh the window until the transaction is processed.</p>
//           </div>
//         )}

//         {/* Show the form only if the transaction is not completed and not processing */}
//         {!transactionStatus && !isProcessing && (
//           <>
//             <h2>QUICK DEPOSIT</h2>
//             <p>
//               Amount to Pay:{' '}
//               <strong>
//                 ₹{transactionDetails.amount} {transactionDetails.currency}
//               </strong>
//             </p>

//             <div className="bank-details">
//               <h3>Bank Details</h3>
//               <div className="bank-info">
//                 <div>
//                   <label>Bank Name:</label>
//                   <span>Indian Overseas Bank</span>
//                 </div>
//                 <div>
//                   <label>Account Number:</label>
//                   <span>234402000000397</span>
//                 </div>
//                 <div>
//                   <label>IFSC Code:</label>
//                   <span>IOBA0002344</span>
//                 </div>
//                 <div>
//                   <label>Account Name:</label>
//                   <span>SS CONTRACTORS</span>
//                 </div>
//               </div>
//             </div>

//             <div className="reference-section">
//               <label htmlFor="reference-number">Enter Reference Number</label>
//               <input
//                 type="text"
//                 id="reference-number"
//                 placeholder="Enter reference number"
//                 value={referenceNumber}
//                 onChange={(e) => setReferenceNumber(e.target.value)}
//               />
//               <button
//                 className="quick-deposit-button"
//                 onClick={handleReferenceSubmit}
//                 disabled={isSubmitting} // Disable the button while submitting
//               >
//                 {isSubmitting ? 'Submitting...' : 'Submit Reference Number'}
//               </button>
//               <p className="attempts-left">
//                 Attempts left: {attemptsLeft}
//               </p>
//             </div>
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// export default QuickDeposit;




// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import './QuickDeposit.css'; // Ensure this file includes the required CSS for animations

// const QuickDeposit = () => {
//   const { transaction_id } = useParams();
//   const [transactionDetails, setTransactionDetails] = useState(null);
//   const [referenceNumber, setReferenceNumber] = useState('');
//   const [transactionStatus, setTransactionStatus] = useState(null);
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const [isProcessing, setIsProcessing] = useState(false); // New state variable for processing
//   const [attemptsLeft, setAttemptsLeft] = useState(5); // Total 5 attempts
//   const [payinCallbackUrl, setPayinCallbackUrl] = useState(null); // Store callback URL

//   // Fetch transaction details from the backend when the component loads
//   useEffect(() => {
//     console.log(`[DEBUG] Fetching transaction details for transaction_id: ${transaction_id}`);

//     fetch(`https://payin.payinfy.com/api/transaction-details/${transaction_id}/`)
//       .then(response => {
//         console.log(`[DEBUG] Transaction details response status: ${response.status}`);
//         return response.json();
//       })
//       .then(data => {
//         console.log(`[DEBUG] Transaction details response data:`, data);

//         if (data.status === 'SUCCESS') {
//           setTransactionDetails(data.transaction);
//           setPayinCallbackUrl(data.transaction.payin_callback_url); // Store callback URL
//         } else {
//           console.error('[ERROR] Transaction details fetch failed:', data.message);
//           alert(data.message);
//         }
//       })
//       .catch(error => {
//         console.error('[ERROR] Error fetching transaction details:', error);
//         alert('Error fetching transaction details');
//       });
//   }, [transaction_id]);

//   const handleReferenceSubmit = () => {
//     if (!referenceNumber) {
//       alert('Please enter a reference number.');
//       console.log('[DEBUG] No reference number provided.');
//       return;
//     }

//     if (attemptsLeft <= 0) {
//       alert('No attempts left.');
//       console.log('[DEBUG] No attempts left.');
//       return;
//     }

//     setIsSubmitting(true); // Start loading
//     setIsProcessing(true); // Show processing loader

//     console.log(`[DEBUG] Sending reference scraping request for transaction_id: ${transaction_id}, reference number: ${referenceNumber}`);

//     fetch(`https://payin.payinfy.com/api/reference-scraping/${transaction_id}/`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         // Add any additional headers if needed, like Authorization:
//         // 'Authorization': 'Bearer your_token_here',
//       },
//       body: JSON.stringify({ reference_number: referenceNumber }), // Ensure the body is correctly formatted
//     })
//       .then(response => {
//         console.log(`[DEBUG] Response status: ${response.status}`);
//         return response.json();
//       })
//       .then(data => {
//         console.log(`[DEBUG] Response data:`, data);
//         setIsSubmitting(false); // End loading

//         if (data.status === 'Processing') {
//           console.log('[DEBUG] Scraping process initiated. Polling for transaction status.');
//           // Poll the status every 5 seconds
//           setTimeout(() => checkTransactionStatus(transaction_id), 5000);
//         } else if (data.status === 'Success') {
//           console.log(`[DEBUG] Transaction successful:`, data);
//           setTransactionDetails(prevDetails => ({
//             ...prevDetails,
//             amount: data.amount,
//           }));
//           setTransactionStatus('SUCCESS');

//           // Redirect to the callback URL after success
//           if (data.payin_callback_url) {
//             console.log(`[DEBUG] Redirecting to payin_callback_url: ${data.payin_callback_url}`);
//             window.location.href = data.payin_callback_url;
//           }
//         } else {
//           console.log(`[DEBUG] Transaction failed. Attempts left: ${attemptsLeft - 1}`);
//           // Decrement attempts left
//           setAttemptsLeft(prevAttempts => prevAttempts - 1);

//           if (attemptsLeft - 1 > 0) {
//             alert(`Transaction failed. You have ${attemptsLeft - 1} more attempts left.`);
//           } else {
//             setTransactionStatus('FAILED');

//             // Redirect to the callback URL after failure
//             if (data.payin_callback_url || payinCallbackUrl) {
//               console.log(`[DEBUG] Redirecting to payin_callback_url: ${data.payin_callback_url || payinCallbackUrl}`);
//               window.location.href = data.payin_callback_url || payinCallbackUrl;
//             } else {
//               alert('Transaction failed and no callback URL provided.');
//             }
//           }
//         }
//       })
//       .catch(error => {
//         setIsSubmitting(false); // End loading
//         setIsProcessing(false); // End processing loader
//         console.error('[ERROR] Error submitting reference number:', error);
//         alert('An error occurred while submitting the reference number.');
//       });
//   };

//   // Poll transaction status until success or failure
//   const checkTransactionStatus = (transaction_id) => {
//     console.log(`[DEBUG] Polling transaction status for transaction_id: ${transaction_id}`);

//     fetch(`https://payin.payinfy.com/api/check-payment-status/${transaction_id}/`)
//       .then(response => response.json())
//       .then(data => {
//         console.log(`[DEBUG] Transaction status response:`, data);

//         if (data.status === 'SUCCESS') {
//           setTransactionStatus('SUCCESS');
//           console.log(`[DEBUG] Transaction status is SUCCESS`);

//           // Optionally redirect to the callback URL after success
//           if (data.payin_callback_url) {
//             console.log(`[DEBUG] Redirecting to payin_callback_url: ${data.payin_callback_url}`);
//             window.location.href = data.payin_callback_url;
//           }
//         } else if (data.status === 'FAILED') {
//           setTransactionStatus('FAILED');
//           console.log('[DEBUG] Transaction status is FAILED');
//         } else {
//           console.log('[DEBUG] Transaction still PENDING. Continuing to poll...');
//           // If still pending, continue polling
//           setTimeout(() => checkTransactionStatus(transaction_id), 5000);
//         }
//       })
//       .catch(error => {
//         console.error('[ERROR] Error fetching transaction status:', error);
//       });
//   };

//   // Warn the user before closing or refreshing the window during processing
//   useEffect(() => {
//     const handleBeforeUnload = (e) => {
//       if (isProcessing) {
//         e.preventDefault();
//         e.returnValue = ''; // Required for some browsers
//       }
//     };

//     if (isProcessing) {
//       window.addEventListener('beforeunload', handleBeforeUnload);
//     } else {
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//     }

//     return () => {
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//     };
//   }, [isProcessing]);

//   if (!transactionDetails) {
//     return <div>Loading transaction details...</div>;
//   }

//   return (
//     <div className="quick-deposit-container">
//       <div className="quick-deposit-box">
//         {/* Display the animated success or failure message */}
//         {transactionStatus === 'SUCCESS' && (
//           <div className="success-message animate">
//             Quick deposit amount of ₹{transactionDetails.amount} Successful
//           </div>
//         )}
//         {transactionStatus === 'FAILED' && (
//           <div className="failed-message animate">
//             Quick deposit failed. Please try again.
//           </div>
//         )}

//         {/* Show the processing loader */}
//         {isProcessing && (
//           <div className="processing-overlay">
//             <div className="loader"></div>
//             <p>Your transaction is being processed.</p>
//             <p>Please don't close or refresh the window until the transaction is processed.</p>
//           </div>
//         )}

//         {/* Show the form only if the transaction is not completed and not processing */}
//         {!transactionStatus && !isProcessing && (
//           <>
//             <h2>QUICK DEPOSIT</h2>
//             <p>
//               Amount to Pay:{' '}
//               <strong>
//                 ₹{transactionDetails.amount} {transactionDetails.currency}
//               </strong>
//             </p>

//             <div className="bank-details">
//               <h3>Bank Details</h3>
//               <div className="bank-info">
//                 <div>
//                   <label>Bank Name:</label>
//                   <span>Indian Overseas Bank</span>
//                 </div>
//                 <div>
//                   <label>Account Number:</label>
//                   <span>234402000000397</span>
//                 </div>
//                 <div>
//                   <label>IFSC Code:</label>
//                   <span>IOBA0002344</span>
//                 </div>
//                 <div>
//                   <label>Account Name:</label>
//                   <span>SS CONTRACTORS</span>
//                 </div>
//               </div>
//             </div>

//             <div className="reference-section">
//               <label htmlFor="reference-number">Enter Reference Number</label>
//               <input
//                 type="text"
//                 id="reference-number"
//                 placeholder="Enter reference number"
//                 value={referenceNumber}
//                 onChange={(e) => setReferenceNumber(e.target.value)}
//               />
//               <button
//                 className="quick-deposit-button"
//                 onClick={handleReferenceSubmit}
//                 disabled={isSubmitting} // Disable the button while submitting
//               >
//                 {isSubmitting ? 'Submitting...' : 'Submit Reference Number'}
//               </button>
//               <p className="attempts-left">
//                 Attempts left: {attemptsLeft}
//               </p>
//             </div>
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// export default QuickDeposit;



// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import './QuickDeposit.css'; // Ensure this file includes the required CSS for animations

// const QuickDeposit = () => {
//   const { transaction_id } = useParams();
//   const [transactionDetails, setTransactionDetails] = useState(null);
//   const [referenceNumber, setReferenceNumber] = useState('');
//   const [transactionStatus, setTransactionStatus] = useState(null);
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const [isProcessing, setIsProcessing] = useState(false); // New state variable for processing
//   const [attemptsLeft, setAttemptsLeft] = useState(5); // Total 5 attempts
//   const [payinCallbackUrl, setPayinCallbackUrl] = useState(null); // Store callback URL

//   // Fetch transaction details from the backend when the component loads
//   useEffect(() => {
//     console.log(`[DEBUG] Fetching transaction details for transaction_id: ${transaction_id}`);

//     fetch(`https://payin.payinfy.com/api/transaction-details/${transaction_id}/`)
//       .then(response => {
//         console.log(`[DEBUG] Transaction details response status: ${response.status}`);
//         return response.json();
//       })
//       .then(data => {
//         console.log(`[DEBUG] Transaction details response data:`, data);

//         if (data.status === 'SUCCESS') {
//           setTransactionDetails(data.transaction);
//           setPayinCallbackUrl(data.transaction.payin_callback_url); // Store callback URL
//         } else {
//           console.error('[ERROR] Transaction details fetch failed:', data.message);
//           alert(data.message);
//         }
//       })
//       .catch(error => {
//         console.error('[ERROR] Error fetching transaction details:', error);
//         alert('Error fetching transaction details');
//       });
//   }, [transaction_id]);

//   const handleReferenceSubmit = () => {
//     if (!referenceNumber) {
//       alert('Please enter a reference number.');
//       console.log('[DEBUG] No reference number provided.');
//       return;
//     }

//     if (attemptsLeft <= 0) {
//       alert('No attempts left.');
//       console.log('[DEBUG] No attempts left.');
//       return;
//     }

//     setIsSubmitting(true); // Start loading
//     setIsProcessing(true); // Show processing loader

//     console.log(`[DEBUG] Sending reference scraping request for transaction_id: ${transaction_id}, reference number: ${referenceNumber}`);

//     fetch(`https://payin.payinfy.com/api/reference-scraping/${transaction_id}/`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         // Add any additional headers if needed, like Authorization:
//         // 'Authorization': 'Bearer your_token_here',
//       },
//       body: JSON.stringify({ reference_number: referenceNumber }), // Ensure the body is correctly formatted
//     })
//       .then(response => {
//         console.log(`[DEBUG] Response status: ${response.status}`);
//         return response.json();
//       })
//       .then(data => {
//         console.log(`[DEBUG] Response data:`, data);
//         setIsSubmitting(false); // End loading

//         if (data.status === 'Processing') {
//           console.log('[DEBUG] Scraping process initiated. Polling for transaction status.');
//           // Poll the status every 5 seconds
//           setTimeout(() => checkTransactionStatus(transaction_id), 5000);
//         } else if (data.status === 'Success') {
//           console.log(`[DEBUG] Transaction successful:`, data);
//           setTransactionDetails(prevDetails => ({
//             ...prevDetails,
//             amount: data.amount,
//           }));
//           setTransactionStatus('SUCCESS');

//           // Redirect to the callback URL after success
//           if (data.payin_callback_url) {
//             console.log(`[DEBUG] Redirecting to payin_callback_url: ${data.payin_callback_url}`);
//             window.location.href = data.payin_callback_url;
//           }
//         } else {
//           console.log(`[DEBUG] Transaction failed. Attempts left: ${attemptsLeft - 1}`);
//           // Decrement attempts left
//           setAttemptsLeft(prevAttempts => prevAttempts - 1);

//           if (attemptsLeft - 1 > 0) {
//             alert(`Transaction failed. You have ${attemptsLeft - 1} more attempts left.`);
//           } else {
//             setTransactionStatus('FAILED');

//             // Redirect to the callback URL after failure
//             if (data.payin_callback_url || payinCallbackUrl) {
//               console.log(`[DEBUG] Redirecting to payin_callback_url: ${data.payin_callback_url || payinCallbackUrl}`);
//               window.location.href = data.payin_callback_url || payinCallbackUrl;
//             } else {
//               alert('Transaction failed and no callback URL provided.');
//             }
//           }
//         }
//       })
//       .catch(error => {
//         setIsSubmitting(false); // End loading
//         setIsProcessing(false); // End processing loader
//         console.error('[ERROR] Error submitting reference number:', error);
//         alert('An error occurred while submitting the reference number.');
//       });
//   };

//   // Poll transaction status until success or failure
//   const checkTransactionStatus = (transaction_id) => {
//     console.log(`[DEBUG] Polling transaction status for transaction_id: ${transaction_id}`);

//     fetch(`https://payin.payinfy.com/api/check-payment-status/${transaction_id}/`)
//       .then(response => {
//         console.log(`[DEBUG] Response status: ${response.status}`);
//         return response.json();
//       })
//       .then(data => {
//         console.log(`[DEBUG] Transaction status response:`, data);

//         if (data.status === 'SUCCESS') {
//           console.log('[DEBUG] Transaction status is SUCCESS');
//           setTransactionStatus('SUCCESS');

//           // Optionally redirect to the callback URL after success
//           if (data.payin_callback_url) {
//             console.log(`[DEBUG] Redirecting to payin_callback_url: ${data.payin_callback_url}`);
//             window.location.href = data.payin_callback_url;
//           }
//         } else if (data.status === 'FAILED') {
//           console.log('[DEBUG] Transaction status is FAILED');
//           setTransactionStatus('FAILED');
//         } else if (data.status === 'PENDING') {
//           console.log('[DEBUG] Transaction still PENDING. Continuing to poll...');
//           // If still pending, continue polling
//           setTimeout(() => checkTransactionStatus(transaction_id), 5000);
//         } else {
//           console.log('[ERROR] Unexpected transaction status:', data.status);
//         }
//       })
//       .catch(error => {
//         console.error('[ERROR] Error fetching transaction status:', error);
//       });
//   };

//   // Warn the user before closing or refreshing the window during processing
//   useEffect(() => {
//     const handleBeforeUnload = (e) => {
//       if (isProcessing) {
//         e.preventDefault();
//         e.returnValue = ''; // Required for some browsers
//       }
//     };

//     if (isProcessing) {
//       window.addEventListener('beforeunload', handleBeforeUnload);
//     } else {
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//     }

//     return () => {
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//     };
//   }, [isProcessing]);

//   if (!transactionDetails) {
//     return <div>Loading transaction details...</div>;
//   }

//   return (
//     <div className="quick-deposit-container">
//       <div className="quick-deposit-box">
//         {/* Display the animated success or failure message */}
//         {transactionStatus === 'SUCCESS' && (
//           <div className="success-message animate">
//             Quick deposit amount of ₹{transactionDetails.amount} Successful
//           </div>
//         )}
//         {transactionStatus === 'FAILED' && (
//           <div className="failed-message animate">
//             Quick deposit failed. Please try again.
//           </div>
//         )}

//         {/* Show the processing loader */}
//         {isProcessing && (
//           <div className="processing-overlay">
//             <div className="loader"></div>
//             <p>Your transaction is being processed.</p>
//             <p>Please don't close or refresh the window until the transaction is processed.</p>
//           </div>
//         )}

//         {/* Show the form only if the transaction is not completed and not processing */}
//         {!transactionStatus && !isProcessing && (
//           <>
//             <h2>QUICK DEPOSIT</h2>
//             <p>
//               Amount to Pay:{' '}
//               <strong>
//                 ₹{transactionDetails.amount} {transactionDetails.currency}
//               </strong>
//             </p>

//             <div className="bank-details">
//               <h3>Bank Details</h3>
//               <div className="bank-info">
//                 <div>
//                   <label>Bank Name:</label>
//                   <span>Indian Overseas Bank</span>
//                 </div>
//                 <div>
//                   <label>Account Number:</label>
//                   <span>234402000000397</span>
//                 </div>
//                 <div>
//                   <label>IFSC Code:</label>
//                   <span>IOBA0002344</span>
//                 </div>
//                 <div>
//                   <label>Account Name:</label>
//                   <span>SS CONTRACTORS</span>
//                 </div>
//               </div>
//             </div>

//             <div className="reference-section">
//               <label htmlFor="reference-number">Enter Reference Number</label>
//               <input
//                 type="text"
//                 id="reference-number"
//                 placeholder="Enter reference number"
//                 value={referenceNumber}
//                 onChange={(e) => setReferenceNumber(e.target.value)}
//               />
//               <button
//                 className="quick-deposit-button"
//                 onClick={handleReferenceSubmit}
//                 disabled={isSubmitting} // Disable the button while submitting
//               >
//                 {isSubmitting ? 'Submitting...' : 'Submit Reference Number'}
//               </button>
//               <p className="attempts-left">
//                 Attempts left: {attemptsLeft}
//               </p>
//             </div>
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// export default QuickDeposit;



// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import './QuickDeposit.css';

// const QuickDeposit = () => {
//   const { transaction_id } = useParams();
//   const [transactionDetails, setTransactionDetails] = useState(null);
//   const [referenceNumber, setReferenceNumber] = useState('');
//   const [transactionStatus, setTransactionStatus] = useState(null);
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const [isProcessing, setIsProcessing] = useState(false);
//   const [attemptsLeft, setAttemptsLeft] = useState(5);
//   const [payinCallbackUrl, setPayinCallbackUrl] = useState(null);

//   useEffect(() => {
//     fetch(`https://payin.payinfy.com/api/transaction-details/${transaction_id}/`)
//       .then(response => response.json())
//       .then(data => {
//         if (data.status === 'SUCCESS') {
//           setTransactionDetails(data.transaction);
//           setPayinCallbackUrl(data.transaction.payin_callback_url);
//         } else {
//           alert(data.message);
//         }
//       })
//       .catch(error => {
//         console.error('Error fetching transaction details:', error);
//         alert('Error fetching transaction details');
//       });
//   }, [transaction_id]);

//   const handleReferenceSubmit = () => {
//     if (!referenceNumber) {
//       alert('Please enter a reference number.');
//       return;
//     }

//     if (attemptsLeft <= 0) {
//       alert('No attempts left.');
//       return;
//     }

//     setIsSubmitting(true);
//     setIsProcessing(true);

//     fetch(`https://payin.payinfy.com/api/reference-scraping/${transaction_id}/`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ reference_number: referenceNumber }),
//     })
//       .then(response => response.json())
//       .then(data => {
//         setIsSubmitting(false);

//         if (data.status === 'Processing') {
//           setTimeout(() => checkTransactionStatus(transaction_id), 5000);
//         } else {
//           setIsProcessing(false);
//           alert('An error occurred while processing your transaction.');
//         }
//       })
//       .catch(error => {
//         setIsSubmitting(false);
//         setIsProcessing(false);
//         console.error('Error submitting reference number:', error);
//         alert('An error occurred while submitting the reference number.');
//       });
//   };

//   const checkTransactionStatus = (transaction_id) => {
//     fetch(`https://payin.payinfy.com/api/check-payment-status/${transaction_id}/`)
//       .then(response => response.json())
//       .then(data => {
//         if (data.status === 'SUCCESS') {
//           setTransactionStatus('SUCCESS');
//           setIsProcessing(false);

//           setTimeout(() => {
//             if (data.payin_callback_url) {
//               window.location.href = data.payin_callback_url;
//             }
//           }, 2000);
//         } else if (data.status === 'FAILED') {
//           setTransactionStatus('FAILED');
//           setIsProcessing(false);

//           setTimeout(() => {
//             if (data.payin_callback_url || payinCallbackUrl) {
//               window.location.href = data.payin_callback_url || payinCallbackUrl;
//             } else {
//               alert('Transaction failed and no callback URL provided.');
//             }
//           }, 2000);
//         } else if (data.status === 'PENDING') {
//           setTimeout(() => checkTransactionStatus(transaction_id), 5000);
//         } else {
//           setIsProcessing(false);
//           alert('An error occurred while processing your transaction.');
//         }
//       })
//       .catch(error => {
//         setIsProcessing(false);
//         console.error('Error fetching transaction status:', error);
//         alert('An error occurred while checking transaction status.');
//       });
//   };

//   useEffect(() => {
//     const handleBeforeUnload = (e) => {
//       if (isProcessing) {
//         e.preventDefault();
//         e.returnValue = '';
//       }
//     };

//     if (isProcessing) {
//       window.addEventListener('beforeunload', handleBeforeUnload);
//     } else {
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//     }

//     return () => {
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//     };
//   }, [isProcessing]);

//   if (!transactionDetails) {
//     return <div>Loading transaction details...</div>;
//   }

//   return (
//     <div className="quick-deposit-container">
//       <div className="quick-deposit-box">
//         {transactionStatus === 'SUCCESS' && (
//           <div className="success-message animate">
//             Quick deposit amount of ₹{transactionDetails.amount} Successful
//           </div>
//         )}
//         {transactionStatus === 'FAILED' && (
//           <div className="failed-message animate">
//             Quick deposit failed. Please try again.
//           </div>
//         )}

//         {isProcessing && (
//           <div className="processing-overlay">
//             <div className="loader"></div>
//             <p>Your transaction is being processed.</p>
//             <p>Please don't close or refresh the window until the transaction is processed.</p>
//           </div>
//         )}

//         {!transactionStatus && !isProcessing && (
//           <>
//             <h2>QUICK DEPOSIT</h2>
//             <p>
//               Amount to Pay:{' '}
//               <strong>
//                 ₹{transactionDetails.amount} {transactionDetails.currency}
//               </strong>
//             </p>

//             <div className="bank-details">
//               <h3>Bank Details</h3>
//               <div className="bank-info">
//                 <div>
//                   <label>Bank Name:</label>
//                   <span>Indian Overseas Bank</span>
//                 </div>
//                 <div>
//                   <label>Account Number:</label>
//                   <span>234402000000397</span>
//                 </div>
//                 <div>
//                   <label>IFSC Code:</label>
//                   <span>IOBA0002344</span>
//                 </div>
//                 <div>
//                   <label>Account Name:</label>
//                   <span>SS CONTRACTORS</span>
//                 </div>
//               </div>
//             </div>

//             <div className="reference-section">
//               <label htmlFor="reference-number">Enter Reference Number</label>
//               <input
//                 type="text"
//                 id="reference-number"
//                 placeholder="Enter reference number"
//                 value={referenceNumber}
//                 onChange={(e) => setReferenceNumber(e.target.value)}
//               />
//               <button
//                 className="quick-deposit-button"
//                 onClick={handleReferenceSubmit}
//                 disabled={isSubmitting}
//               >
//                 {isSubmitting ? 'Submitting...' : 'Submit Reference Number'}
//               </button>
//               <p className="attempts-left">
//                 Attempts left: {attemptsLeft}
//               </p>
//             </div>
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// export default QuickDeposit;


// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import './QuickDeposit.css'; // Ensure this file includes the required CSS
// // import copyIcon from './copy-icon.svg'; // If you're using a custom copy icon
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCopy } from '@fortawesome/free-solid-svg-icons';

// const QuickDeposit = () => {
//   const { transaction_id } = useParams();
//   const [transactionDetails, setTransactionDetails] = useState(null);
//   const [referenceNumber, setReferenceNumber] = useState('');
//   const [transactionStatus, setTransactionStatus] = useState(null);
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const [isProcessing, setIsProcessing] = useState(false);
//   const [attemptsLeft, setAttemptsLeft] = useState(5);
//   const [payinCallbackUrl, setPayinCallbackUrl] = useState(null);

//   useEffect(() => {
//     fetch(`https://payin.payinfy.com/api/transaction-details/${transaction_id}/`)
//       .then(response => response.json())
//       .then(data => {
//         if (data.status === 'SUCCESS') {
//           setTransactionDetails(data.transaction);
//           setPayinCallbackUrl(data.transaction.payin_callback_url);
//         } else {
//           alert(data.message);
//         }
//       })
//       .catch(error => {
//         console.error('Error fetching transaction details:', error);
//         alert('Error fetching transaction details');
//       });
//   }, [transaction_id]);

//   const copyToClipboard = (text) => {
//     navigator.clipboard.writeText(text)
//       .then(() => {
//         alert(`${text} copied to clipboard`);
//       })
//       .catch((err) => {
//         console.error('Could not copy text: ', err);
//       });
//   };

//   const handleReferenceSubmit = () => {
//     if (!referenceNumber) {
//       alert('Please enter a reference number.');
//       return;
//     }

//     if (attemptsLeft <= 0) {
//       alert('No attempts left.');
//       return;
//     }

//     setIsSubmitting(true);
//     setIsProcessing(true);

//     fetch(`https://payin.payinfy.com/api/reference-scraping/${transaction_id}/`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ reference_number: referenceNumber }),
//     })
//       .then(response => response.json())
//       .then(data => {
//         setIsSubmitting(false);

//         if (data.status === 'Processing') {
//           setTimeout(() => checkTransactionStatus(transaction_id), 5000);
//         } else {
//           setIsProcessing(false);
//           alert('An error occurred while processing your transaction.');
//         }
//       })
//       .catch(error => {
//         setIsSubmitting(false);
//         setIsProcessing(false);
//         console.error('Error submitting reference number:', error);
//         alert('An error occurred while submitting the reference number.');
//       });
//   };

//   const checkTransactionStatus = (transaction_id) => {
//     fetch(`https://payin.payinfy.com/api/check-payment-status/${transaction_id}/`)
//       .then(response => response.json())
//       .then(data => {
//         if (data.status === 'SUCCESS') {
//           setTransactionStatus('SUCCESS');
//           setIsProcessing(false);

//           setTimeout(() => {
//             if (data.payin_callback_url) {
//               window.location.href = data.payin_callback_url;
//             }
//           }, 2000);
//         } else if (data.status === 'FAILED') {
//           setIsProcessing(false);
//           setAttemptsLeft(prevAttempts => {
//             const newAttemptsLeft = prevAttempts - 1;
//             if (newAttemptsLeft > 0) {
//               alert(`Transaction failed. You have ${newAttemptsLeft} attempts left.`);
//             } else {
//               // No attempts left, show failure message and redirect
//               setTransactionStatus('FAILED');
//               setTimeout(() => {
//                 if (data.payin_callback_url || payinCallbackUrl) {
//                   window.location.href = data.payin_callback_url || payinCallbackUrl;
//                 } else {
//                   alert('Transaction failed and no callback URL provided.');
//                 }
//               }, 2000);
//             }
//             return newAttemptsLeft;
//           });
//         } else if (data.status === 'PENDING') {
//           setTimeout(() => checkTransactionStatus(transaction_id), 5000);
//         } else {
//           setIsProcessing(false);
//           alert('An error occurred while processing your transaction.');
//         }
//       })
//       .catch(error => {
//         setIsProcessing(false);
//         console.error('Error fetching transaction status:', error);
//         alert('An error occurred while checking transaction status.');
//       });
//   };

//   useEffect(() => {
//     const handleBeforeUnload = (e) => {
//       if (isProcessing) {
//         e.preventDefault();
//         e.returnValue = '';
//       }
//     };

//     if (isProcessing) {
//       window.addEventListener('beforeunload', handleBeforeUnload);
//     } else {
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//     }

//     return () => {
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//     };
//   }, [isProcessing]);

//   if (!transactionDetails) {
//     return <div>Loading transaction details...</div>;
//   }

//   return (
//     <div className="quick-deposit-container">
//       <div className="quick-deposit-box">
//         {transactionStatus === 'SUCCESS' && (
//           <div className="success-message animate">
//             Quick deposit amount of ₹{transactionDetails.amount} Successful
//           </div>
//         )}
//         {transactionStatus === 'FAILED' && (
//           <div className="failed-message animate">
//             Quick deposit failed. Please try again.
//           </div>
//         )}

//         {isProcessing && (
//           <div className="processing-overlay">
//             <div className="loader">
//               <div></div>
//               <div></div>
//             </div>
//             <p>Your transaction is being processed.</p>
//             <p>Please don't close or refresh the window until the transaction is processed.</p>
//           </div>
//         )}

//         {!transactionStatus && !isProcessing && (
//           <>
//             <h2>QUICK DEPOSIT</h2>
//             <p>
//               Amount to Pay:{' '}
//               <strong>
//                 ₹{transactionDetails.amount} {transactionDetails.currency}
//               </strong>
//             </p>

//             <div className="bank-details">
//               <h3>Bank Details</h3>
//               <div className="bank-info">
//                 {[
//                   { label: 'Bank Name', value: 'Indian Overseas Bank' },
//                   { label: 'Account Number', value: '234402000000397' },
//                   { label: 'IFSC Code', value: 'IOBA0002344' },
//                   { label: 'Account Name', value: 'SS CONTRACTORS' },
//                 ].map((detail, index) => (
//                   <div className="bank-card" key={index}>
//                     <div className="bank-card-content">
//                       <label>{detail.label}:</label>
//                       <span>{detail.value}</span>
//                     </div>
//                     <FontAwesomeIcon
//                       icon={faCopy}
//                       className="copy-icon"
//                       onClick={() => copyToClipboard(detail.value)}
//                     />
//                   </div>
//                 ))}
//               </div>
//             </div>

//             <div className="reference-section">
//               <label htmlFor="reference-number">Enter Reference Number</label>
//               <input
//                 type="text"
//                 id="reference-number"
//                 placeholder="Enter reference number"
//                 value={referenceNumber}
//                 onChange={(e) => setReferenceNumber(e.target.value)}
//               />
//               <button
//                 className="quick-deposit-button"
//                 onClick={handleReferenceSubmit}
//                 disabled={isSubmitting}
//               >
//                 {isSubmitting ? 'Submitting...' : 'Submit Reference Number'}
//               </button>
//               <p className="attempts-left">
//                 Attempts left: {attemptsLeft}
//               </p>
//             </div>
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// export default QuickDeposit;


import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './QuickDeposit.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

const QuickDeposit = () => {
  const { transaction_id } = useParams();
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [referenceNumber, setReferenceNumber] = useState('');
  const [transactionStatus, setTransactionStatus] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [attemptsLeft, setAttemptsLeft] = useState(5);
  const [payinCallbackUrl, setPayinCallbackUrl] = useState(null);

  // Notification state
  const [notification, setNotification] = useState({ message: '', type: '' });

  const showNotification = (message, type) => {
    setNotification({ message, type });
    setTimeout(() => {
      setNotification({ message: '', type: '' });
    }, 3000);
  };

  useEffect(() => {
    fetch(`https://payin.payinfy.com/api/transaction-details/${transaction_id}/`)
      .then(response => response.json())
      .then(data => {
        if (data.status === 'SUCCESS') {
          setTransactionDetails(data.transaction);
          setPayinCallbackUrl(data.transaction.payin_callback_url);
        } else {
          showNotification(data.message, 'error');
        }
      })
      .catch(error => {
        console.error('Error fetching transaction details:', error);
        showNotification('Error fetching transaction details', 'error');
      });
  }, [transaction_id]);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        showNotification(`${text} copied to clipboard`, 'success');
      })
      .catch((err) => {
        console.error('Could not copy text: ', err);
        showNotification('Failed to copy text', 'error');
      });
  };

  const handleReferenceSubmit = () => {
    if (!referenceNumber) {
      showNotification('Please enter a reference number.', 'error');
      return;
    }

    if (attemptsLeft <= 0) {
      showNotification('No attempts left.', 'error');
      return;
    }

    setIsSubmitting(true);
    setIsProcessing(true);

    fetch(`https://payin.payinfy.com/api/reference-scraping/${transaction_id}/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ reference_number: referenceNumber }),
    })
      .then(response => response.json())
      .then(data => {
        setIsSubmitting(false);

        if (data.status === 'Processing') {
          setTimeout(() => checkTransactionStatus(transaction_id), 5000);
        } else {
          setIsProcessing(false);
          showNotification('An error occurred while processing your transaction.', 'error');
        }
      })
      .catch(error => {
        setIsSubmitting(false);
        setIsProcessing(false);
        console.error('Error submitting reference number:', error);
        showNotification('An error occurred while submitting the reference number.', 'error');
      });
  };

  const checkTransactionStatus = (transaction_id) => {
    fetch(`https://payin.payinfy.com/api/check-payment-status/${transaction_id}/`)
      .then(response => response.json())
      .then(data => {
        if (data.status === 'SUCCESS') {
          setTransactionStatus('SUCCESS');
          setIsProcessing(false);

          setTimeout(() => {
            if (data.payin_callback_url) {
              window.location.href = data.payin_callback_url;
            }
          }, 2000);
        } else if (data.status === 'FAILED') {
          setIsProcessing(false);
          setAttemptsLeft(prevAttempts => {
            const newAttemptsLeft = prevAttempts - 1;
            if (newAttemptsLeft > 0) {
              showNotification(`Transaction failed. You have ${newAttemptsLeft} attempts left.`, 'error');
            } else {
              // No attempts left, show failure message and redirect
              setTransactionStatus('FAILED');
              setTimeout(() => {
                if (data.payin_callback_url || payinCallbackUrl) {
                  window.location.href = data.payin_callback_url || payinCallbackUrl;
                } else {
                  showNotification('Transaction failed and no callback URL provided.', 'error');
                }
              }, 2000);
            }
            return newAttemptsLeft;
          });
        } else if (data.status === 'PENDING') {
          setTimeout(() => checkTransactionStatus(transaction_id), 5000);
        } else {
          setIsProcessing(false);
          showNotification('An error occurred while processing your transaction.', 'error');
        }
      })
      .catch(error => {
        setIsProcessing(false);
        console.error('Error fetching transaction status:', error);
        showNotification('An error occurred while checking transaction status.', 'error');
      });
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isProcessing) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    if (isProcessing) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isProcessing]);

  if (!transactionDetails) {
    return <div>Loading transaction details...</div>;
  }

  return (
    <div className="quick-deposit-container">
      {notification.message && (
        <div className={`notification ${notification.type}`}>
          {notification.message}
        </div>
      )}

      <div className="quick-deposit-box">
        {transactionStatus === 'SUCCESS' && (
          <div className="success-message animate">
            Quick deposit amount of ₹{transactionDetails.amount} Successful
          </div>
        )}
        {transactionStatus === 'FAILED' && (
          <div className="failed-message animate">
            Quick deposit failed. Please try again.
          </div>
        )}

        {isProcessing && (
          <div className="processing-overlay">
            <div className="loader">
              <div></div>
              <div></div>
            </div>
            <p>Your transaction is being processed.</p>
            <p>Please don't close or refresh the window until the transaction is processed.</p>
          </div>
        )}

        {!transactionStatus && !isProcessing && (
          <>
            <h2>QUICK DEPOSIT</h2>
            <p>
              Amount to Pay:{' '}
              <strong>
                ₹{transactionDetails.amount} {transactionDetails.currency}
              </strong>
            </p>

            <div className="bank-details">
              <h3>Bank Details</h3>
              <div className="bank-info">
                {[
                  { label: 'Bank Name', value: 'Indian Overseas Bank' },
                  { label: 'Account Number', value: '234402000000397' },
                  { label: 'IFSC Code', value: 'IOBA0002344' },
                  { label: 'Account Name', value: 'SS CONTRACTORS' },
                ].map((detail, index) => (
                  <div className="bank-card" key={index}>
                    <div className="bank-card-content">
                      <label>{detail.label}:</label>
                      <span>{detail.value}</span>
                      <FontAwesomeIcon
                        icon={faCopy}
                        className="copy-icon"
                        onClick={() => copyToClipboard(detail.value)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="reference-section">
              <label htmlFor="reference-number">Enter Reference Number</label>
              <input
                type="text"
                id="reference-number"
                placeholder="Enter reference number"
                value={referenceNumber}
                onChange={(e) => setReferenceNumber(e.target.value)}
              />
              <button
                className="quick-deposit-button"
                onClick={handleReferenceSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Submit Reference Number'}
              </button>
              <p className="attempts-left">
                Attempts left: {attemptsLeft}
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default QuickDeposit;
